.wrapper {
  margin-top: var(--c-space-40);
  /* Top margin for anything below */
  & + * {
    margin-top: var(--v-space-8) !important;
  }
  & + ol,
  & + ul {
    margin-top: var(--v-space-4) !important;
  }
  @media (--v-from-md) {
    margin-top: var(--v-space-64);

    /* Top margin for anything below */
    & + * {
      margin-top: var(--v-space-16) !important;
    }
  }
}
