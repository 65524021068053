.wrapper {
  > details {
    margin-top: var(--v-space-24);

    @media (--v-from-md) {
      margin-top: var(--v-space-32);
    }

    & + details {
      margin-top: var(--v-space-16);
    }
  }

  > h5 {
    margin-top: var(--v-space-24);

    @media (--v-from-md) {
      margin-top: var(--v-space-32);
    }

    + * {
      margin-top: var(--v-space-4);
    }
  }
}
