.wrapper {
  margin-top: var(--v-space-32);

  /* Top margin for anything below */
  & + * {
    margin-top: var(--v-space-8) !important;
  }

  @media (--v-from-md) {
    margin-top: var(--c-space-40);
  }
}
