.promotionWrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--v-space-24);
  padding: var(--v-space-16);
  background-color: var(--v-color-background-secondary);
  & picture {
    width: 150px;
    margin: auto;
  }
  @media (--oip-from-ml) {
    grid-template-columns: 1fr 1fr;
    gap: var(--v-space-32);
    padding: calc(var(--v-space-8) * 7);
  }
}

.link {
  margin-top: var(--v-space-8);
  & > a {
    padding-bottom: 0;
  }
}
