.logosDisplay {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: var(--v-space-16);
  column-gap: var(--v-space-24);
  padding: var(--v-space-32) var(--v-space-24);
  background-color: var(--v-color-background-secondary);
  @media (--oip-from-ml) {
    grid-template-columns: repeat(5, 1fr);
    column-gap: var(--v-space-32);
    padding: var(--v-space-64) var(--v-space-24) calc(var(--v-space-8) * 9);
  }
}
