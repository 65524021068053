.wrapper {
  width: 100%;
  margin-top: var(--v-space-24);
  margin-bottom: 0;
  & + picture {
    margin-top: var(--v-space-32);
    @media (--v-from-md) {
      margin-top: var(--v-space-40);
    }
  }
  @media (--v-from-md) {
    margin-top: var(--v-space-32);
  }
}
