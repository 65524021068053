.wrapper {
  display: grid;
  grid-template: 1fr / 1fr;
  width: 100%;
  min-height: 380px;
  background-color: var(--v-color-background-secondary);

  @media (--v-from-lg) {
    grid-template-columns: 1fr 1fr;
  }
}

.imageWrapper {
  position: relative;
  width: 100%;
  min-height: 350px;

  > picture {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    min-height: 350px;
    padding: 0;
    transition: opacity 0.5s ease;
    inset-inline-start: 0;
    > img {
      height: 100%;
      margin-top: 0px !important;
    }
  }
}

.opacity0 {
  opacity: 0 !important;
}

.opacity1 {
  opacity: 1 !important;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  min-height: 390px;
  padding: var(--c-space-40) var(--v-space-16);

  @media (--v-from-md) {
    padding: var(--c-space-40) var(--v-space-24);
  }
}

.title {
  position: relative;
  display: block;
  color: var(--v-color-foreground-secondary);

  &::after {
    position: absolute;
    bottom: -(var(--v-space-8));
    display: block;
    width: 0;
    height: 3px;
    content: '';
    background-color: var(--v-color-foreground-accent-blue);
    transition: width 0.3s ease;
    inset-inline-start: 0;
  }
}

.title.active {
  color: var(--v-color-always-black);
  &::after {
    width: var(--v-space-48);
  }
}
