.input {
  width: 100%;
  min-height: 82px;
  text-align: start;
}

.form {
  width: 100%;
  min-height: 90px;
  @media (--v-from-md) {
    max-width: 410px;
  }
}
