.left-column-width {
  --left-column-width: 20px;
}

.step {
  composes: left-column-width;

  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: var(--left-column-width);
  height: var(--left-column-width);
  border: 1px solid var(--v-color-foreground-secondary);
  border-radius: 50%;
}

.strokeWrapper {
  composes: left-column-width;

  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  width: var(--left-column-width);
}

.stroke {
  display: flex;
  width: 1px;
  height: 100%;
  background-color: var(--v-color-ornament-primary);
}

.text {
  max-width: var(--c-content-max-width);
  margin-inline-start: var(--v-space-16);
}
