.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: var(--v-space-32);
  column-gap: var(--v-space-16);
  @media (--v-from-md) {
    grid-template-columns: 1fr 1fr;
    row-gap: var(--c-space-40);
  }
  @media (--v-from-lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
