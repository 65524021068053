.card:hover p:global(.button-text) {
  text-decoration-line: underline;
  text-decoration-thickness: 0.125em;
  text-underline-offset: 0.125em;
  text-decoration-skip-ink: none;
}

.resetMargins {
  margin-top: 0 !important;
}
